import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Slider from 'react-rangeslider';
//import 'react-rangeslider/lib/index.css';
import './slider.css';
import './Ente.css';

class Ente extends Component {
   constructor(props) {
      super(props);

      this.state = {
         idUser: '',
         idEnte: '',
         lang: '',
         nomeEnte: '',
         imgEnte: '',
         descrizioneEnte: {},
         coins: '',
         coinsToDonate: 0,
         loading: false
      }

      this.initialState = this.state;
   }

   componentDidMount() {
      /*
      setTimeout(() => {
         window.location.href = `ofreetheapp://dona_${12}`;
      }, 1000)
      */

      //console.log(window.location.pathname);
      let pathname = window.location.pathname;
      pathname = pathname.replace('/ente/', '');

      const arrayInfo = pathname.split('*');
      //console.log(arrayInfo);

      if (arrayInfo.length > 2) {
         const idEnte = arrayInfo[0];
         const idUser = arrayInfo[1];
         const lang = arrayInfo[2];

         axios.get(`http://api.ofree.eu/getDonationInfo?cryptParameters=${idEnte}*${idUser}`)
            .then(res => {
               //console.log(res) 
               if (res.status === 200) {
                  const { brandName, brandImage, note_it, note_en, coinDonable } = res.data.data;

                  let descrizioneEnte;
                  if (lang === 'it') {
                     descrizioneEnte = note_it
                  } else {
                     descrizioneEnte = note_en;
                  }

                  //console.log(descrizioneEnte);

                  this.setState({ idEnte, idUser, lang, nomeEnte: brandName, imgEnte: brandImage, descrizioneEnte, coins: coinDonable })
               }            
            })
            .catch(err => {
               //console.log(err)
            })

         
      } else {
         //console.log('non abbiamo dati in url')
      }
   }

   onPressDona() {
      const { coinsToDonate, coins } = this.state;

      if (coinsToDonate === 0 || coins === 0) {
         return;
      }

      this.setState({ loading: true });

      /*
      axios.post('http://stage.api.ofree.eu/donateCoinFromExternalApp', {
         value: coinsToDonate,
         uid: this.state.idUser,
         brandId: this.state.idEnte
      })
      */
      axios.get(`http://api.ofree.eu/donateCoinFromExternalApp?value=${coinsToDonate}&uid=${this.state.idUser}&brandId=${this.state.idEnte}`)
      .then(res => {
         //console.log(res)
         if (res.status === 200) {
            this.setState(this.initialState);
            //const { data } = res.data;

            window.location.href = `ofreetheapp://dona_${coinsToDonate}`;
         }
      })
      .catch(error => {
         //console.log(error)
      })
   }

   render() {
      const { nomeEnte, imgEnte, descrizioneEnte, coins, coinsToDonate, lang } = this.state;

      //console.log(descrizioneEnte, lang)
      //aggiungerea anche && !descrizioneEnte
      if (!lang) {
         return (
            <div style={{ textAlign: 'center', display: 'grid', alignContent: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
               <Loader
                  type='ThreeDots'
                  color='#202124'
                  height={100}
                  width={100}
               />
            </div>
         );
      }

      const textButton = lang === 'it' ? 'Vota questo progetto' : 'Vote this project'

      return (
         <div className='App'>
            <div style={{ position: 'relative' }}>
               <img
                  src={imgEnte}
                  className='ImageEnte'
                  alt=''
               />

               <div 
                  style={{ position: 'absolute', bottom: 0, width: '100%', height: 50 }}
                  className='Sfumatura'
               />
            </div>

            <div className='Body'>
               <p className='BaseP Nome'>{nomeEnte}</p>

               <p className='BaseP Descrizione'>{descrizioneEnte}</p>

               <div style={{ textAlign: 'center', marginTop: 24 }}>
                  <p className='BaseP Nome' style={{ marginBottom: 32 }}>{coinsToDonate}</p>

                  <Slider
                     max={parseInt(coins)}
                     min={0}
                     tooltip={false}
                     value={this.state.coinsToDonate}
                     onChange={value => this.setState({ coinsToDonate: value })}
                  />

                  {
                     this.state.loading ?
                     <div style={{ marginTop: 24 }}>
                        <div className="BtnDona Loader" style={{ margin: 'auto' }}>
                           <Loader
                              type='ThreeDots'
                              color='#383b41'
                              height={30}
                              width={30}
                           />
                        </div>
                     </div>
                     :
                     <button
                        className='BtnDona'
                        onClick={() => this.onPressDona()}
                     >
                        {textButton}
                     </button>
                  }
                  

                  <div style={{ height: 32 }} />
               </div>
            </div>

         </div>
      );
   }
}

export default Ente;
