import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import './Recupero.css';


class Recupero extends Component {
	constructor(props) {
		super(props);

		let pathname = this.props.location.search;
		//console.log(pathname);

		this.state = {
			password: '',
			confermaP: '',
			error: '',
			loading: false,
			firstAttr: pathname,
			result: ''
		}
	}

	onPressReset() {
		const { password, confermaP, firstAttr } = this.state;

		if (!firstAttr.includes('?')) {
			return;
		}

		//console.log(password, confermaP);
		if (!password || !confermaP) {
			return;
		}

		if (password !== confermaP) {
			this.setState({ error: 'Conferma password errato' });
			return;
		}

		this.setState({ loading: true, error: '' });

		axios.post(`http://api.ofree.eu/changePassword${firstAttr}&psw=${password}`)
		.then(response => {
			//console.log(response.data)

			this.setState({ loading: false, result: 'Password aggiornata con successo' });
		})
		.catch(error => {
			//console.log(error)
			this.setState({ loading: false, error: 'Errore durante la connessione, si prega di riprovare.' });
		})
	}

	render() {
		return (
			<div className='App'>
				
				<p className='Title'>Reset Password</p>

				<div style={{ margin: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<input
						className='Form-input'
						placeholder='Password'
						type='password'
						onChange={(event) => this.setState({ password: event.target.value })}
					/>

					<input
						className='Form-input'
						placeholder='Conferma password'
						type='password'
						onChange={(event) => this.setState({ confermaP: event.target.value })}
					/>

					{
						this.state.error ?
						<p style={{ margin: 0, textAlign: 'center', color: 'red', fontSize: 16, fontWeight: 500 }}>
							{this.state.error}
						</p>
						: null
					}

					{
						this.state.loading ?
						<div style={{ marginTop: 24 }}>
							<div className="BtnReset Loader" style={{ margin: 'auto' }}>
								<Loader
			                  type='ThreeDots'
			                  color='#383b41'
			                  height={30}
			                  width={30}
			               />
							</div>
						</div>
						:
						<button
	                  className='BtnReset'
	                  onClick={() => this.onPressReset()}
	               >
	                  Reset
	               </button>
					}

					{
						this.state.result ?
						<div style={{ marginTop: 24 }}>
							<p style={{ margin: 0, textAlign: 'center', color: 'green', fontSize: 16, fontWeight: 500 }}>
								{this.state.result}
							</p>
						</div>
						: null
					}
					
				</div>
			</div>
		);	
	}
}

export default Recupero;
